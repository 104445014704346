var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-2 col-lg-2" },
      [
        _c(
          "q-form",
          { ref: "editForm" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBLDETAIL" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: {
                                isSubmit: _vm.isSave,
                                url: _vm.saveUrl,
                                param: _vm.matrix,
                                mappingType: _vm.mappingType,
                                label: "LBLSAVEALL",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveMatrix,
                                btnCallback: _vm.saveMatrixCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-plant", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          type: "edit",
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.matrix.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.matrix, "plantCd", $$v)
                          },
                          expression: "matrix.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-select", {
                        attrs: {
                          editable: _vm.editable,
                          codeGroupCd: "RAM_TECHNIQUE_CD",
                          required: true,
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "ramTechniqueCd",
                          label: "LBL0001031",
                        },
                        model: {
                          value: _vm.matrix.ramTechniqueCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.matrix, "ramTechniqueCd", $$v)
                          },
                          expression: "matrix.ramTechniqueCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          required: true,
                          label: "LBL0001254",
                          name: "matrixName",
                        },
                        model: {
                          value: _vm.matrix.matrixName,
                          callback: function ($$v) {
                            _vm.$set(_vm.matrix, "matrixName", $$v)
                          },
                          expression: "matrix.matrixName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "LBLREMARK",
                          name: "remarks",
                        },
                        model: {
                          value: _vm.matrix.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.matrix, "remarks", $$v)
                          },
                          expression: "matrix.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-radio", {
                        attrs: {
                          editable: _vm.editable,
                          comboItems: _vm.useFlagItems,
                          label: "LBLUSEFLAG",
                          name: "useFlag",
                        },
                        model: {
                          value: _vm.matrix.useFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.matrix, "useFlag", $$v)
                          },
                          expression: "matrix.useFlag",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-3 col-lg-3" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm risk-standards",
            attrs: { title: "LBL0001032" },
          },
          [
            _c(
              "template",
              { slot: "card-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "색상 적용", icon: "check" },
                          on: { btnClicked: _vm.acceptStandardMatrixRisk },
                        })
                      : _vm._e(),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { showLoading: false, label: "", icon: "add" },
                          on: { btnClicked: _vm.addR },
                        })
                      : _vm._e(),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: {
                            showLoading: false,
                            label: "",
                            icon: "remove",
                          },
                          on: { btnClicked: _vm.removeR },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "template",
              { slot: "card-detail" },
              _vm._l(_vm.matrix.standards, function (range, idx) {
                return _c(
                  "div",
                  { key: idx, staticClass: "col-12" },
                  [
                    _c(
                      "q-item",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticStyle: { "align-items": "center" },
                      },
                      [
                        _c(
                          "q-item-section",
                          { attrs: { side: "", top: "" } },
                          [
                            _vm.editable
                              ? _c("q-checkbox", {
                                  model: {
                                    value: range.checked,
                                    callback: function ($$v) {
                                      _vm.$set(range, "checked", $$v)
                                    },
                                    expression: "range.checked",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "cursor-pointer risk-name",
                            staticStyle: {
                              "min-width": "90px",
                              "max-width": "130px",
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(range.riskContents) + " "),
                            _c(
                              "q-popup-edit",
                              {
                                attrs: {
                                  disable: !_vm.editable,
                                  "content-class": "bg-accent text-white",
                                },
                                on: {
                                  save: function ($event) {
                                    return _vm.datachange(range)
                                  },
                                },
                                model: {
                                  value: range.riskContents,
                                  callback: function ($$v) {
                                    _vm.$set(range, "riskContents", $$v)
                                  },
                                  expression: "range.riskContents",
                                },
                              },
                              [
                                _c("q-input", {
                                  attrs: {
                                    dark: "",
                                    color: "white",
                                    dense: "",
                                    autofocus: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c("q-icon", {
                                              attrs: { name: "edit" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: range.riskContents,
                                    callback: function ($$v) {
                                      _vm.$set(range, "riskContents", $$v)
                                    },
                                    expression: "range.riskContents",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("q-range", {
                          staticClass: "q-mr-md",
                          attrs: {
                            disable: !_vm.editable,
                            min: _vm.rangeComp.min,
                            max: _vm.rangeComp.max,
                            step: 1,
                            "label-always": "",
                            "drag-range": "",
                            label: "",
                            markers: "",
                            snap: "",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.datachange(range)
                            },
                          },
                          model: {
                            value: range.model,
                            callback: function ($$v) {
                              _vm.$set(range, "model", $$v)
                            },
                            expression: "range.model",
                          },
                        }),
                        _c(
                          "q-badge",
                          { style: { "background-color": range.color } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$label("LBL0001263")) + " "
                            ),
                            _c(
                              "q-popup-proxy",
                              [
                                _c("q-color", {
                                  staticClass: "my-picker",
                                  attrs: { "no-header": "", "no-footer": "" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.datachange(range)
                                    },
                                  },
                                  model: {
                                    value: range.color,
                                    callback: function ($$v) {
                                      _vm.$set(range, "color", $$v)
                                    },
                                    expression: "range.color",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-7 col-lg-7" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm risk",
            attrs: { title: "LBL0001011", noMarginPadding: true },
          },
          [
            _c(
              "template",
              { slot: "card-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-text", {
                      ref: "selectSeries",
                      attrs: {
                        id: "beforeTxt",
                        editable: _vm.editable,
                        disabled: _vm.setSeriesDisabled,
                        name: "selectSeries",
                        beforeText: _vm.$label("LBL0001257"),
                      },
                      on: { dataChange: _vm.change },
                      model: {
                        value: _vm.selectSeries.y,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectSeries, "y", $$v)
                        },
                        expression: "selectSeries.y",
                      },
                    }),
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "LBL0001258", icon: "check" },
                          on: { btnClicked: _vm.accept },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c(
                    "q-toolbar",
                    { staticClass: "bg-primary text-white matrixTitle" },
                    [
                      _c("q-toolbar-title", [
                        _vm._v(_vm._s(_vm.$label("LBL0001259"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-list",
                    { attrs: { bordered: "", padding: "" } },
                    [
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c(
                                "q-item-label",
                                { staticClass: "text-deep-orange" },
                                [
                                  _c("q-icon", {
                                    attrs: { name: "crop_square" },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0001009")) + " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "q-item-label",
                                {
                                  style: { whiteSpace: "pre-line" },
                                  attrs: { caption: "" },
                                },
                                [_vm._v(" " + _vm._s(_vm.frequencyData) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c(
                                "q-item-label",
                                { staticClass: "text-deep-orange" },
                                [
                                  _c("q-icon", {
                                    attrs: { name: "crop_square" },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0001010")) + " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "q-item-label",
                                {
                                  style: { whiteSpace: "pre-line" },
                                  attrs: { caption: "" },
                                },
                                [_vm._v(" " + _vm._s(_vm.strengtyData) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-item",
                        [
                          _c(
                            "q-item-section",
                            [
                              _c(
                                "q-item-label",
                                { staticClass: "text-deep-orange" },
                                [
                                  _c("q-icon", {
                                    attrs: { name: "crop_square" },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$label("LBL0001011")) + " "
                                  ),
                                ],
                                1
                              ),
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _vm._v(" " + _vm._s(_vm.riskData) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-9" },
                [
                  _c("apexchart", {
                    ref: "chart",
                    attrs: {
                      width: _vm.chartWidth,
                      height: "350",
                      type: "heatmap",
                      options: _vm.chartOptions,
                      series: _vm.series,
                    },
                    on: {
                      dataPointSelection: _vm.dataPointSelection,
                      mouseMove: _vm.mouseMove,
                      mouseLeave: _vm.mouseLeave,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "LBL0001260", noMarginPadding: true },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                [
                  _c(
                    "c-table",
                    {
                      ref: "tableF",
                      attrs: {
                        title: "LBL0001009",
                        tableId: "f01",
                        hideBottom: true,
                        usePaging: false,
                        filtering: false,
                        columns: _vm.gridF.columns,
                        data: _vm.matrix.frequencies,
                        gridHeight: _vm.gridF.height,
                        selection: "multiple",
                        rowKey: "ramMatrixFrequencyId",
                        editable: _vm.editable,
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "table-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _vm.editable
                                ? _c("c-btn", {
                                    attrs: {
                                      showLoading: false,
                                      label: "LBLADD",
                                      icon: "add",
                                    },
                                    on: { btnClicked: _vm.addF },
                                  })
                                : _vm._e(),
                              _vm.editable &&
                              _vm.matrix.frequencies &&
                              _vm.matrix.frequencies.length > 0
                                ? _c("c-btn", {
                                    attrs: {
                                      showLoading: false,
                                      label: "LBLREMOVE",
                                      icon: "remove",
                                    },
                                    on: { btnClicked: _vm.removeF },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                [
                  _c(
                    "c-table",
                    {
                      ref: "tableS",
                      attrs: {
                        title: "LBL0001010",
                        tableId: "s01",
                        hideBottom: true,
                        usePaging: false,
                        filtering: false,
                        columns: _vm.gridS.columns,
                        data: _vm.matrix.strengties,
                        gridHeight: _vm.gridS.height,
                        selection: "multiple",
                        rowKey: "ramMatrixStrengthId",
                        editable: _vm.editable,
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "table-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _vm.editable
                                ? _c("c-btn", {
                                    attrs: {
                                      showLoading: false,
                                      label: "LBLADD",
                                      icon: "add",
                                    },
                                    on: { btnClicked: _vm.addS },
                                  })
                                : _vm._e(),
                              _vm.editable &&
                              _vm.matrix.strengties &&
                              _vm.matrix.strengties.length > 0
                                ? _c("c-btn", {
                                    attrs: {
                                      showLoading: false,
                                      label: "LBLREMOVE",
                                      icon: "remove",
                                    },
                                    on: { btnClicked: _vm.removeS },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }